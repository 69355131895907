<template>
  <div>
    <NotificationFilter @apply="getAllNotificationsList" />
    <NotificationReminderContainer
      :update-pagination="updatePagination"
      :is-loading-component="isLoadingComponent"
      :notification-actions="notificationActions"
      @update="typeAction"
      @filterRecord="filterRecord"
    />
    <!-- SCHEDULED NOTIFICATION -->
    <ScheduledNotificationCreateUpdate
      v-if="scheduleModel"
      :show-model="scheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :concerned="currentActionObject.concernedList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      @handleClick="toogleScheduleModel"
      @update="filterRecord"
    />
    <!-- UNSCHEDULED NOTIFICATION -->
    <UnScheduledNotificationCreateUpdate
      v-if="unScheduleModel"
      :show-model="unScheduleModel"
      :label="currentActionObject.label"
      :options="currentActionObject.optionsList"
      :feature-and-actions="currentActionObject"
      :edit="currentNotificationObject"
      module="class"
      @handleClick="toggleUnScheduleModel"
      @update="updateNotificationsList"
      @addNotificationAlert="addNotification"
    />
  </div>
</template>

<script>
import ScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/AddEditNotificationAlertTemplate1.vue'
import UnScheduledNotificationCreateUpdate from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import NotificationReminderContainer from '@/src/components/Settings/notifications/NotificationReminderContainer.vue'
import { paginationRangeHandler } from '@components/BaseComponent/pagination.util.js'
import NotificationFilter from '@views/settings/notifications/attendance/campus/CampusAttendanceNotificationFilters.vue'
/* Utils or mixins */
import { mapActions } from 'vuex'
import notificationMixin from '@src/mixins/notification-mixin.js'
import NOTIFICATION_PATHS, {
  NOTIFICATION_RECIEVERS,
} from '@src/constants/notification-constants.js'
import { getNotificationsOptionsList } from '@utils/notifications'
export default {
  components: {
    ScheduledNotificationCreateUpdate,
    UnScheduledNotificationCreateUpdate,
    NotificationReminderContainer,
    NotificationFilter,
  },
  mixins: [notificationMixin],
  data() {
    return {
      dataPayload: {
        payload: '',
        path: NOTIFICATION_PATHS.CLASS,
      },
      optionList: {
        add_class: [NOTIFICATION_RECIEVERS.SPECIFIC_STAFF],
        edit_class: [NOTIFICATION_RECIEVERS.SPECIFIC_STAFF],
        delete_class: [NOTIFICATION_RECIEVERS.SPECIFIC_STAFF],
      },
    }
  },

  methods: {
    setActionList() {
      this.notificationActions = {
        add_class: {
          title: 'Reminder for Add Class',
          label: 'Add Class',
          feature: 'class',
          action: 'add_class',
          loading: false,
          count: 0,
        },

        edit_class: {
          title: 'Reminder for Edit Class',
          label: 'Edit Class',
          feature: 'class',
          action: 'edit_class',
          loading: false,
          count: 0,
        },
        delete_class: {
          title: 'Reminder for Delete Class',
          label: 'Delete Class',
          feature: 'class',
          action: 'delete_class',
          loading: false,
          count: 0,
        },
      }
    },
    setConcernedAndOptionsList() {
      Object.keys(this.optionList).forEach((option) => {
        this.notificationActions[option].optionsList = getNotificationsOptionsList(
          this.optionList[option],
        )
      })
    },
    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'NOTIFICATION_REMINDER',
          buttons: [
            {
              title: 'CLASS_NOTIFICATIONS',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'ADD_CLASS',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.add_class
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'EDIT_CLASS',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.edit_class
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },

                {
                  title: 'DELETE_CLASS',
                  action: {
                    func: () => {
                      this.currentActionObject = this.notificationActions.delete_class
                      this.toggleUnScheduleModel()
                    },
                  },
                  payload: {},
                },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
